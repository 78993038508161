// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fiches-js": () => import("./../../../src/pages/fiches.js" /* webpackChunkName: "component---src-pages-fiches-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lieux-js": () => import("./../../../src/pages/lieux.js" /* webpackChunkName: "component---src-pages-lieux-js" */),
  "component---src-pages-statistiques-js": () => import("./../../../src/pages/statistiques.js" /* webpackChunkName: "component---src-pages-statistiques-js" */),
  "component---src-templates-fiche-js": () => import("./../../../src/templates/Fiche.js" /* webpackChunkName: "component---src-templates-fiche-js" */),
  "component---src-templates-lieu-js": () => import("./../../../src/templates/Lieu.js" /* webpackChunkName: "component---src-templates-lieu-js" */)
}

